import { HOME_TABS } from '../../constants/public';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './navbar.module.css'
import Logo from '../../resource/imgs/logo.png'
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const NavBar = ({ pathname }) => {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(true)

  let scrollTop = 0
  let topValue = 0
  const getScrollTop = () => {
    let scrollTop = 0;
    if (document?.documentElement && document?.documentElement?.scrollTop) {
      scrollTop = document?.documentElement.scrollTop;
    } else if (document?.body) {
      scrollTop=document?.body.scrollTop;
    }
    return scrollTop > 0 ? scrollTop : 0;
  }

  const bindHandleScroll = () => {
    scrollTop = getScrollTop();
    if (scrollTop  <= topValue) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
    setTimeout(() => {
      topValue = scrollTop
    }, 0);
  }

  useEffect(() => {
    window.addEventListener('scroll', bindHandleScroll)
    return () => {
      window.removeEventListener('scroll', bindHandleScroll)
    }
  }, [])

  const onLogoClick = () => {
    if (global.fullpageApi && (!pathname || pathname.length <= 1)) {
      global.fullpageApi.moveTo(1)
    }
  }

  return (
    <>
    <div className={classNames(styles.navbar, isShow ? styles.navbarActive : null)}>
      <Link className={styles.logo} to="/" onClick={onLogoClick}>
        <img className={styles.logoIcon} src={Logo} alt="logo" />
      </Link>
      <div className={styles.tab}>
        {
          Object.keys(HOME_TABS).map((key) => {
            const tab = HOME_TABS[key]
            return (
              <Link
                to={tab.path}
                className={styles.tabItem}
                key={key}
              >
                {t(tab.trans_key)}
              </Link>
            )
          })
        }
      </div>
    </div>
    </>
  )
}

export default NavBar
