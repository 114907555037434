import React from "react";
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../resource/imgs/logo.png'
import styles from './footer.module.css'
import { useTranslation } from 'react-i18next';
import classNames from "classnames";

const Footer = () => {
  const location = useLocation()
  const { t } = useTranslation();

  const onLogoClick = () => {
    if (global.fullpageApi && (!location.pathname || location.pathname.length <= 1)) {
      global.fullpageApi.moveTo(1)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <div className={styles.menuLeft}>
          <Link className={styles.logo} to="/" onClick={onLogoClick}>
            <img className={styles.logoIcon} src={Logo} alt="logo" />
          </Link>
          <div className={styles.sectionTitle}>{t('footer.slogan')}</div>
        </div>
        <div className={styles.menuSection}>
          <div className={styles.sectionTitle}>{t('footer.company')}</div>
          <Link className={styles.item} to="/home/wedo">{t('footer.wedo')}</Link>
          <Link className={styles.item} to="/home/about">{t('footer.about')}</Link>
          <Link className={styles.item} to="/home/roadmap">{t('footer.roadmap')}</Link>
        </div>
        <div className={styles.line} />
        <div className={styles.menuSection}>
          <div className={styles.sectionTitle}>{t('footer.games')}</div>
          <a className={styles.item} target="_blank" rel="noreferrer" href="http://www.myrzx.cn/">{t('footer.myrzx')}</a>
          <a className={styles.item} target="_blank" rel="noreferrer" href="http://www.noflandstory.com/">{t('footer.nofland')}</a>
          <a className={styles.item} target="_blank" rel="noreferrer" href="http://www.danhaotuan.com/download.html">{t('footer.mitako')}</a>
        </div>
        <div className={styles.line} />
        <div className={styles.menuSection}>
          <div className={styles.sectionTitle}>{t('footer.contact')}</div>
          <div className={styles.item}>Hanami Co., Ltd.</div>
          <div className={styles.item}>Game : game@mitako.cn</div>
          <div className={classNames(styles.item, styles.item1)}>E-commerce: xiaoyan@mitako.cn</div>
          <div className={classNames(styles.item, styles.item1)}>Support: service@mitako.cn</div>
          <div className={styles.item}>Address: 3-11-5, Ueno, Taito Ku, Tokyo To, 110-0005, Japan</div>
        </div>
      </div>
      <div className={styles.icp}>
        <div className={styles.icpLine} />
        <div className={styles.terms}>
          <span>@2024 Hanami All rights reserved</span>
          <div className={styles.termsLine}>|</div>
          <a className={styles.termsA} target="_blank" rel="noreferrer" href="https://www.mitakomarket.com/lang/ja/commercial_transaction_law.html">特定商取引法に基づく表記</a>
        </div>
        <a className={styles.icpText} href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">沪ICP备2023010178号-1</a>
      </div>
    </div>
  )
}

export default Footer;
