import React, { useEffect, useRef } from 'react'
import LOOPOP from '../../resource/LOOPOP.mp4'
import styles from './top.module.css'

const HomeTop = ({ t }) => {
  const videoPlayer = useRef()

  // useEffect(() => {
  //   if (videoPlayer.current) {
  //     const isPlaying = videoPlayer.current.currentTime > 0 && !videoPlayer.current.paused && !videoPlayer.current.ended 
  //     && videoPlayer.current.readyState > videoPlayer.current.HAVE_CURRENT_DATA
  //     if (!isPlaying) {
  //       videoPlayer.current.play()
  //     }
  //   }
  // })

  return (
    <div className={styles.container}>
      <video
        ref={videoPlayer}
        className={styles.videoView}
        src={LOOPOP}
        muted={true}
        autoPlay={true}
        loop={true}
        controls={false}
        playsInline={true}
        
      />
      <div className={styles.content} data-aos="zoom-out" data-aos-duration={1800} data-aos-once={true}>
        <p className={styles.title}>{t('banner.title')}</p>
        <p className={styles.slogan}>{t('banner.des')}</p>
      </div>
    </div>
  )
}

export default HomeTop
