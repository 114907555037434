import React from 'react'
import styles from './title.module.css'

const Title = ({ title }) =>  (
  <div className={styles.top}>
    <div className={styles.dot} />
    <span className={styles.title}>{title}</span>
    <div className={styles.dot} />
  </div>
)

export default Title
