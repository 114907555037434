import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import styles from './roadmap.module.css'
import FinishIcon from '../../resource/imgs/home/icn_xuanze.png'
import Title from './title'

const Roadmap = ({ t }) => {
  const [showH5Layout, setShowH5Layout] = useState(window.innerWidth <= 700)

  const handleResize = (e) => {
    setShowH5Layout(e.target.innerWidth <= 700)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const roadmaps = [
    {
      time: t('roadmap.14.title'),
      status: 2,
      contents: [
        t('roadmap.14.content.part1'),
      ],
    },
    {
      time: t('roadmap.16.title'),
      status: 2,
      contents: [
        t('roadmap.16.content.part1'),
      ],
    },
    {
      time: t('roadmap.17.title'),
      status: 2,
      contents: [
        t('roadmap.17.content.part1'),
      ],
    },
    {
      time: t('roadmap.18.title'),
      status: 2,
      contents: [
        t('roadmap.18.content.part1'),
        t('roadmap.18.content.part2'),
      ],
    },
    {
      time: t('roadmap.19.title'),
      status: 2,
      contents: [
        t('roadmap.19.content.part1'),
      ],
    },
    {
      time: t('roadmap.22.title'),
      status: 2,
      contents: [
        t('roadmap.22.content.part1'),
        t('roadmap.22.content.part2'),
        t('roadmap.22.content.part3'),
      ],
    },
    {
      time: t('roadmap.23.title'),
      status: 1,
      contents: [
        t('roadmap.23.content.part1'),
        t('roadmap.23.content.part2'),
      ],
    },
  ]

  const renderTimelineItemContent = (item) => {
    return (        
      <div className={styles.timelineItemContent}>
        <ul>
          {
            item.contents.map((content) => (
              <li key={content}>{content}</li>
            ))
          }
        </ul>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Title title={t('roadmap.subtitle')} />
      <div className={styles.timeline}>
        <div className={styles.line} />
        {
          roadmaps.map((item, index) => {
            if (showH5Layout) {
              return (
                <div
                  className={styles.timelineItem}
                  key={item.time}
                >
                  <div className={styles.nodeCircle}>
                    <div className={styles.node} />
                  </div>
                  <div className={styles.itemMobile}>
                    <div className={styles.timeContainR}>
                      {
                        item.status === 2 && (
                          <img className={styles.finishIcon} src={FinishIcon} alt="finish" />
                        )
                      }
                      <span className={classNames(styles.time, item.status === 1 ? styles.timeActive : null)}>
                        {item.time}
                      </span>
                    </div>
                    {renderTimelineItemContent(item)}
                  </div>
                </div>
              )
            }
            if (index % 2 !== 0) {
              return (
                <div
                  className={styles.timelineItem}
                  key={item.time}
                >
                  <div className={styles.itemSpace}></div>
                  <div className={styles.nodeCircle}>
                    <div className={styles.node} />
                  </div>
                  <div
                    className={classNames(styles.itemR, 'roadmap_ani')}
                    data-aos="slide-left"
                    data-aos-duration="2000"
                    data-aos-once="true"
                    data-aos-delay={index * 500}
                    >
                    <div className={styles.timeContainR}>
                      {
                        item.status === 2 && (
                          <img className={styles.finishIcon} src={FinishIcon} alt="finish" />
                        )
                      }
                      <span className={classNames(styles.time, item.status === 1 ? styles.timeActive : null)}>
                        {item.time}
                      </span>
                    </div>
                    {renderTimelineItemContent(item)}
                  </div>
                </div>
              )
            }
            return (
              <div
                className={styles.timelineItem}
                key={item.time}
              >
                <div
                  className={classNames(styles.itemL, 'roadmap_ani')}
                  data-aos="slide-right"
                  data-aos-duration="2000"
                  data-aos-once="true"
                  data-aos-delay={index * 500}
                >
                  <div className={styles.timeContainL}>
                    <span className={classNames(styles.time, item.status === 1 ? styles.timeActive : null)}>
                      {item.time}
                    </span>
                    {
                      item.status === 2 && (
                        <img className={styles.finishIcon} src={FinishIcon} alt="finish" />
                      )
                    }
                  </div>
                  {renderTimelineItemContent(item)}
                </div>
                <div className={styles.nodeCircle}>
                  <div className={styles.node} />
                </div>
                <div className={styles.itemSpace}></div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Roadmap
