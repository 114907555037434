import Home from './screens/home/home';

const routes = [
  {
    path: '/home/:part',
    component: Home,
    exact: true,
  },
  {
    path: '/',
    component: Home,
    exact: true,
  }
];

export default routes;