import React, { useState } from "react";
import styles from './faq.module.css'
import AddIcon from '../../resource/imgs/home/icn_jiahao.png'
import SubIcon from '../../resource/imgs/home/icn_jianhao.png'
import Title from "./title";
import classNames from "classnames";

const FAQ = ({ t }) => {
  // const [extend, setExtend] = useState([])
  const [extendIndex, setExtendIndex] = useState(-1)

  const onExtend = (index) => {
    setExtendIndex((pre) => pre === index ? -1 : index)
    // const newExtend = extend.concat([])
    // const position = newExtend.indexOf(index)
    // if (position > -1) {
    //   newExtend.splice(position, 1)
    // } else {
    //   newExtend.push(index)
    // }
    // setExtend(newExtend)
  }

  /* eslint-disable */
  const faqs = [
    {
      q: t('join.job1.name'),
      a: t('join.job1.des')
    },
    {
      q: t('join.job2.name'),
      a: t('join.job2.des')
    },
    {
      q: t('join.job3.name'),
      a: t('join.job3.des')
    },
    {
      q: t('join.job4.name'),
      a: t('join.job4.des')
    },
    {
      q: t('join.job5.name'),
      a: t('join.job5.des')
    },
    {
      q: t('join.job6.name'),
      a: t('join.job6.des')
    },
  ]
  /* eslint-enable */
  return (
    <div className={styles.container}>
      <Title title={t('join.title')} />
      <div className={styles.faqs}>
        {
          faqs.map((item, index) => (
            <div className={styles.item} key={`${item.q}-${index}`}>
              <div className={styles.line} />
              <div className={styles.questionContain} onClick={() => onExtend(index)}>
                <span className={styles.question}>{item.q}</span>
                <img className={styles.extendIcon} src={extendIndex === index ? SubIcon : AddIcon} alt="extend" />
              </div>
              <div
                className={classNames(styles.answer, extendIndex === index ? (index === 2 || index === 4 ? styles.answerLongActive : styles.answerActive) : null)}
              >
                {item.a}
              </div>
            </div>
          ))
        }
        <div className={styles.line} />
      </div>
    </div>
  )
}

export default FAQ
