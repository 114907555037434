
export const HOME_TABS = {
  WE_DO: {
    trans_key: 'navbar.wedo',
    label: 'WHAT WE DO',
    path: '/home/wedo',
  },
  ABOUT_US: {
    trans_key: 'navbar.about_us',
    label: 'ABOUT US',
    path: '/home/about',
  },
  PRODUCTS: {
    trans_key: 'navbar.products',
    label: 'PRODUCTS',
    path: '/home/products',
  },
  ROAD_MAP: {
    trans_key: 'navbar.roadmap',
    label: 'ROADMAP',
    path: '/home/roadmap',
  },
  PARTNER: {
    trans_key: 'navbar.partner',
    label: 'PARTNER',
    path: '/home/partner',
  },
  JOIN_US: {
    trans_key: 'navbar.join_us',
    label: 'JOIN US',
    path: '/home/join',
  },
}

export const ANDROID_DOWNLOAD_URL = 'https://www.loopop.io/h5/download' // 'https://play.google.com/store/apps/details?id=io.loopop.androidapp'
export const IOS_DOWNLOAD_URL = 'https://testflight.apple.com/join/3n1MuVMI' // 'https://apps.apple.com/us/app/loopop-in-love-love-in/id1645281723'
