import React, { useState } from 'react'
import PublicIcon from '../../resource/imgs/img3.jpg'
import PrivateIcon from '../../resource/imgs/img4.jpg'
import styles from './culture.module.css'
import Title from './title'
import classNames from 'classnames'

const Culture = ({ t }) => {
  const [selectIndex, setIndex] = useState(-1)

  const culture = [
    {
      img: PublicIcon,
      title: t('culture.part1.title'),
      des: t('culture.part1.des'),
    },
    {
      img: PrivateIcon,
      title: t('culture.part2.title'),
      des: t('culture.part2.des'),
    },
  ]
  return (
    <div className={styles.container}>
      <Title title={t('culture.title')} />
      <div className={styles.cultures}>
        {
          culture.map((item, index) => (
            <div
              className={classNames(styles.cultureItemContain, 'culture_ani')}
              data-aos={index === 1 ? 'fade-left' : 'fade-right'}
              data-aos-duration="2000"
              data-aos-once="true"
              key={item.title}
            >
              <div
                className={styles.cultureItem}
                onMouseEnter={() => setIndex(index)}
                onMouseLeave={() => setIndex(-1)}
              >
                {index === 0 && <img className={classNames(styles.cultureItemCover, styles.cultureItemCoverL)} src={item.img} alt="" />}
                <div className={styles.cultureItemContent}>
                  <span className={styles.cultureItemTitle}>{item.title}</span>
                  <span className={styles.cultureItemDes}>{item.des}</span>
                </div>
                {index === 1 && <img className={classNames(styles.cultureItemCover, styles.cultureItemCoverR)} src={item.img} alt="" />}
              </div>
            </div>
          ))
        }
      </div>
      <div className={styles.bottom} />
    </div>
  )
}

export default Culture
