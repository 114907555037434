import React from "react";
import styles from './partners.module.css';
import Title from "./title";
import PartnerIcon1 from '../../resource/imgs/partners/1.png'
import PartnerIcon2 from '../../resource/imgs/partners/2.png'
import PartnerIcon3 from '../../resource/imgs/partners/3.png'
import PartnerIcon4 from '../../resource/imgs/partners/4.png'
import PartnerIcon5 from '../../resource/imgs/partners/5.png'
import PartnerIcon6 from '../../resource/imgs/partners/6.png'
import PartnerIcon7 from '../../resource/imgs/partners/7.png'
import PartnerIcon8 from '../../resource/imgs/partners/8.png'
import PartnerIcon9 from '../../resource/imgs/partners/9.png'
import PartnerIcon10 from '../../resource/imgs/partners/10.png'
import PartnerIcon11 from '../../resource/imgs/partners/11.png'
import PartnerIcon12 from '../../resource/imgs/partners/12.png'

const Partners = ({ t }) => {
  const partners = [
    {
      icon: PartnerIcon1,
      link: 'https://pf.sootang.com/',
    },
    {
      icon: PartnerIcon2,
      link: 'http://www.st-notes.jp/',
    },
    {
      icon: PartnerIcon3,
      link: 'https://tapioca.co.jp/',
    },
    {
      icon: PartnerIcon4,
      link: 'https://karutassu.com/',
    },
    {
      icon: PartnerIcon5,
      link: 'https://www.neogate.jp/',
    },
    {
      icon: PartnerIcon6,
      link: 'https://twincre.com/',
    },
    {
      icon: PartnerIcon7,
      link: 'https://stasto.co.jp/',
    },
    {
      icon: PartnerIcon8,
      link: 'https://armabianca.com/',
    },
    {
      icon: PartnerIcon9,
      link: 'https://bushiroad-store.com/',
    },
    {
      icon: PartnerIcon10,
      link: 'https://edith-online.com/'
    },
    {
      icon: PartnerIcon11,
      link: 'https://cabi-net.co.jp/'
    },
    {
      icon: PartnerIcon12,
      link: 'https://yell-world.jp/'
    }
  ]

  return (
    <div className={styles.container}>
      <Title title={t('partner.title')} />
      <div className={styles.content}>{t('partner.content')}</div>
      <div className={styles.main}>
        {
          partners.map((item, index) => (
            <div className={styles.partner} key={`${index}-${item.icon}`}>
              <img className={styles.partnerIcon} src={item.icon} alt="" />
            </div>
          ))
        }
      </div>
    </div>
  )
};

export default Partners;
