import React from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import './App.css';
import NavBar from './components/navbar/navbar';
import routes from './routes';

function App() {
  const location = useLocation()

  return (
    <div className="App">
      <NavBar pathname={location.pathname} />
      <Switch>
        {
          routes.map((route) => {
            const {
              path
            } = route;
            return (
              <Route
                key={path}
                path={path}
                exact={route.exact}
                render={(props) => {
                  if (path === '*') {
                    return <Redirect key={path} to='/' />;
                  }
                  return <route.component {...props}></route.component>
                }}
              />
            );
          })
        }
      </Switch>
    </div>
  );
}

export default App;
