import HomeTop from '../../components/home/top'
import Wedo from '../../components/home/wedo'
import Features from '../../components/home/features'
import Trending from '../../components/home/trending'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './home.module.css'
import Footer from '../../components/footer/footer'
import Roadmap from '../../components/home/roadmap'
import FAQ from '../../components/home/faq'
import Partners from '../../components/home/partners'
import Culture from '../../components/home/culture'
import ReactFullpage from '@fullpage/react-fullpage';
import classNames from 'classnames'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { part } = useParams()
  const { t } = useTranslation()
  const [fullpageReady, setFullpageReady] = useState(false)
  const fullpageObj = useRef()

  const sections = useRef([
    {
      key: 'top',
      component: HomeTop,
    },
    {
      key: 'wedo',
      component: Wedo,
    },
    {
      key: 'about',
      component: Features,
    },
    {
      key: 'culture',
      component: Culture,
    },
    {
      key: 'products',
      component: Trending,
    },
    {
      key: 'roadmap',
      component: Roadmap,
    },
    {
      key: 'partner',
      component: Partners,
    },
    {
      key: 'join',
      component: FAQ,
      footer: Footer,
    },
  ]).current

  useEffect(() => {
    if (part && fullpageReady) {
      const index = sections.findIndex((item) => item.key === part)
      if (fullpageObj.current) {
        setTimeout(() => {
          fullpageObj.current.moveTo(index + 1)
        }, 1000);
      }
    } else if (fullpageReady) {
      if (fullpageObj.current) {
        fullpageObj.current.moveTo(1)
      }
    }
  }, [part, fullpageReady, sections])

  return (
    <ReactFullpage
      licenseKey={'123'}
      scrollingSpeed={1000} /* Options here */
      scrollOverflow={true}
      render={({ state, fullpageApi }) => {
        console.log('state:::', state);
        fullpageObj.current = fullpageApi
        global.fullpageApi = fullpageApi
        if (fullpageApi && !fullpageReady) {
          setFullpageReady(true)
        }
        return (
          <ReactFullpage.Wrapper>
            {
              sections.map((item, index) => (
                <div className={classNames('section', styles.section)} id={item.key} key={item.key}>
                  <>
                    <item.component t={t} />
                    {item.footer && <item.footer />}
                  </>
                </div>
              ))
            }
          </ReactFullpage.Wrapper>
        );
      }}
      afterLoad={(origin, destination, direction) => {
        const key = get(sections, `[${destination.index}].key`, '')
        if (key) {
          const doms = document.getElementsByClassName(`${key}_ani`)
          console.log('doms:::', doms);
          for (const item of doms) {
            item.classList.add('aos-animate')
          }
        }
      }}
    />
  )
}
