import React, { useState } from 'react'
import PublicIcon from '../../resource/imgs/img1.jpg'
import PrivateIcon from '../../resource/imgs/img2.jpg'
import AssetIcon from '../../resource/imgs/features/assets.jpg'
import styles from './features.module.css'
import Title from './title'

const Features = ({ t }) => {
  const [selectIndex, setIndex] = useState(-1)

  const about = [
    {
      img: PublicIcon,
      title: t('about.story.title'),
      des: t('about.story.des'),
    },
    {
      img: PrivateIcon,
      title: t('about.culture.title'),
      des: t('about.culture.des'),
    },
  ]
  return (
    <div className={styles.container}>
      <Title title={t('about.title')} />
      <div className={styles.features}>
        {
          about.map((item, index) => (
            <div
              className="about_ani"
              data-aos={index === 1 ? 'fade-down' : 'fade-up'}
              data-aos-duration="2000"
              data-aos-once="true"
              key={item.title}
            >
              <div
                className={styles.featureItem}
                style={{ backgroundImage: `url(${item.img})`, backgroundRepeat: 'none', backgroundSize: 'cover' }}
                onMouseEnter={() => setIndex(index)}
                onMouseLeave={() => setIndex(-1)}
              >
                <div className={styles.featureItemMask}></div>
                <div className={styles.titleContain}>
                  <span className={styles.featureTitle}>{item.title}</span>
                </div>
                <div className={styles.des}>{item.des}</div>
              </div>
            </div>
          ))
        }
      </div>
      <div className={styles.bottom} />
    </div>
  )
}

export default Features
