import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

import 'swiper/css';
import MyrzxIcon from '../../resource/imgs/product/myrzx.png'
import NoflandIcon from '../../resource/imgs/product/nofland.png'
import Mitako2Icon from '../../resource/imgs/product/mitako_logo.png'
import MitakoIcon from '../../resource/imgs/product/mitako2.png'

import styles from './trending.module.css'
import Title from "./title";

const Trending = ({ t }) => {
  SwiperCore.use([Autoplay])

  const products = [
    {
      name: t('product.content1.name'),
      cover: MyrzxIcon,
      des: t('product.content1.des'),
      download: 'http://www.myrzx.cn/'
    },
    {
      name: t('product.content2.name'),
      cover: NoflandIcon,
      des: t('product.content2.des'),
      download: 'http://www.noflandstory.com/'
    },
    {
      name: t('product.content3.name'),
      cover: Mitako2Icon,
      des: t('product.content3.des'),
      download: 'http://www.danhaotuan.com/download.html'
    },
    {
      name: t('product.content4.name'),
      cover: MitakoIcon,
      des: t('product.content4.des'),
      download: ''
    },
    // {
    //   name: t('product.content3.name'),
    //   cover: MitakoIcon,
    //   des: t('product.content3.des'),
    //   download: 'http://www.danhaotuan.com/download.html'
    // }
  ]

  return (
    <div className={styles.container}>
      <Title title={t('product.title')} />
      <div className={styles.main}>
        {
          products.map((item, index) => (
            <div className={styles.ideo} key={item.name}>
              {
                item.covers ? (
                  <Swiper
                    className={styles.avatars}
                    autoplay={{  delay: 3000 }}
                    loop
                  >
                    {
                      item.covers.map((cover, index) => (
                        <SwiperSlide key={index}>
                          <img className={styles.avatar} src={cover} alt="avatar" />
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                ) : null
              }
              {
                item.cover ? (
                  <img className={styles.avatar} src={item.cover} alt="avatar" id={item.name} />
                ) : null
              }
              <div className={styles.line} />
              <div className={styles.name}>{item.name}</div>
              <div className={styles.des}>{item.des}</div>
              {
                item.download && (
                  <div className={styles.downloadContain}>
                    <a className={styles.download} target="_blank" rel="noreferrer" href={item.download}>{t('product.download')}</a>
                  </div>
                )
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Trending
